import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import CloseSvg from './svg/CloseSvg';
import UserSvg1 from './svg/UserSvg1';
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import { postStudyAbroad } from '../actions/MainAction';
import { changeStateValue } from '../redux/MainReducer';
import PhoneSvg4 from './svg/PhoneSvg4';

import { validatePhoneNumberLength, isValidPhoneNumber, isPossiblePhoneNumber } from 'libphonenumber-js'


class StudyAbroadPopup extends Component {
  state = {
    name: '',
    surname: '',
    phone_number: '',
    program: this.props.program,
    degree: this.props.degree,
    country: this.props.country_name,
    university: this.props.universty_name,
    blog: "",
    successMessage: '',
    btnDisable: false,
    errors: {},
    countryCode: 'az',


  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangePhone = (phone_number, country) => {
    this.setState({ phone_number });
    this.getValidNumber(phone_number, country?.countryCode)
  };

  handleSubmit = () => {
    const { name, phone_number, country, university, blog, successMessage, surname, program, degree, } = this.state;
    const { postStudyAbroad, contentData, phoneValidFormat } = this.props;
    let errors = {};

    if (!phoneValidFormat) {
      errors.phone = `${contentData?.enter_phone_number}`;
    }
    if (!name) {
      errors.name = `${contentData?.enter_name}`;
    }
    if (!surname) {
      errors.surname = `${contentData?.enter_surname}`
    }
    if (!phone_number) {
      errors.phone = `${contentData?.enter_phone_number}`;
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    const data = {
      name: name + " " + surname, // fullName.trim(),
      phone_number: phone_number,
      country,
      university,
      blog: blog || this.props.blogtitle,
      program,
      degree,

    };
    this.setState({ btnDisable: true })
    postStudyAbroad(data)
      .then((resp) => {
        // console.log(data);
        this.setState({ btnDisable: false })
        if (resp === "success") {
          this.props.changeStateValue({ name: "popUpVisible", value: true })
          this.props.onClose();
          this.setState({
            name: '',
            surname: '',
            country: '',
            university: '',
            phone_number: '',
            blog: '',
            program: '',
            degree: '',
            errors: {},
          })
        } else {

        }
      })
      .catch((error) => {
        console.log(error.response)
        // Hata durumu
        this.setState({ successMessage: '', errors: { form: 'Form submission failed!' } });
      });
  }

  getValidNumber(phone_number, code) {
    const phoneNumber = isValidPhoneNumber(phone_number, code.toUpperCase());
    this.props.changeStateValue({ name: "phoneValidFormat", value: phoneNumber })
    // return phoneNumber
  }

  render() {
    const { name, surname, phone_number, successMessage, btnDisable, errors } = this.state;
    const { show, onClose, children, country_name, universty_name, blogtitle, contentData, degree, program, phoneValidFormat } = this.props;

    if (!show) {
      return null;
    }
    // console.log(phoneValidFormat)
    return (
      <div className='popup-bg  ' onClick={onClose}>
        <div className='popup bg-[#fff] rounded-[8px] max-w-[600px] m-[16px] max-[400px]:max-w-[300px]' onClick={(e) => e.stopPropagation()}>
          {children}

          <div className='flex justify-between border-b-[2px] '>
            <div></div>
            <div className='flex justify-between w-[68%]'>
              <h3 className='font-[600] text-[#032552] p-[22px_0px] text-[24px] text-center max-w-[500px] max-[400px]:text-[18px] max-[400px]:p-[14px_0]'>
                {contentData?.get_information}
              </h3>
              <div className='flex items-start '>
                <button onClick={onClose} className='m-[7px]'>
                  <CloseSvg />
                </button>
              </div>
            </div>
          </div>



          <div className='grid grid-cols-2 gap-[20px] p-[16px] max-[410px]:grid-cols-1'>

            <div className='flex flex-col gap-[8px]'>
              <div className='flex flex-col gap-[3px]'>
                <div className='flex items-center gap-[5px]'>
                  <div>
                    <UserSvg1 />
                  </div>
                  <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_name}</span>
                </div>
                {errors.name && <span className='text-red-500 text-[12px]'>{errors.name}</span>}
              </div>



              <div>
                <div className='flex items-center border-[1px] rounded-[8px] p-[10px]'>
                  <input
                    className=' w-full'
                    placeholder={contentData?.popup_name}
                    name='name'
                    value={name}
                    onChange={this.handleChange}
                    id='name'
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-[8px]'>
              <div className='flex flex-col gap-[3px]'>
                <div className='flex items-center gap-[5px]'>
                  <div>
                    <UserSvg1 />
                  </div>
                  <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_surname}</span>
                </div>
                {errors.surname && <span className='text-red-500 text-[12px]'>{errors.surname}</span>}
              </div>


              <div className='flex flex-col'>
                <div className='flex items-center border-[1px] rounded-[8px] p-[10px]'>
                  <input
                    className=' w-full'
                    placeholder={contentData?.popup_surname}
                    name='surname'
                    value={surname}
                    onChange={this.handleChange}
                    id='surname'
                  />
                </div>
              </div>

            </div>

          </div>


          <div className='flex flex-col w-full gap-[5px] p-[0px_16px]'>
            <div className='flex flex-col gap-[5px]'>

              <div className='flex w-full flex-col gap-[3px]'>
                <div className='flex items-center gap-[5px]'>
                  <div>
                    <PhoneSvg4 />
                  </div>
                  <span className='text-[14px] font-[550] text-[#000]'>{contentData?.phone_number}</span>
                </div>
                {errors.phone && <span className='text-red-500 text-[12px]'>{errors.phone}</span>}
              </div>


            </div>

            <div className='border-[1px] w-full  rounded-[8px] '>
              <PhoneInput
                containerStyle={{
                  border: "none",
                  margin: "0",
                }}

                inputStyle={{
                  backgroundColor: "#f2f2f2",
                  padding: '0px',
                  marginLeft: '38px',
                  borderRadius: "5px",
                  border: 'none',
                  background: 'none',
                  width: '100%'
                }}

                buttonStyle={{
                  backgroundColor: "transparent",
                  border: "none"
                }}
                dropdownStyle={{
                  borderRadius: '0px'
                }}
                country={'az'}
                value={phone_number}
                onChange={this.handleChangePhone}
                className="custom-flag"
              />
            </div>
          </div>




          <div className='max-[400px]:mt-[6px]'>
            {/* {
                blogtitle !== undefined ?
                <div className=''>
                  <span className='text-[14px] font-[550] text-[#032552] '>{blogtitle}</span> 
                </div>:null
              } */}


            {
              country_name !== undefined ?
                <div className='p-[0px_20px] w-full flex items-center gap-[6px]'>
                  <span className='text-[13px] font-[550] text-[#000] mt-[3px]'>{contentData?.popup_interested_country}  </span>
                  <span className='text-[14px] font-[550] text-[#032552] '>{country_name}</span>
                </div> : null
            }


            {
              universty_name !== undefined ?
                <div className='p-[0px_20px] w-full flex items-center gap-[6px]'>
                  <span className='text-[13px] font-[550] text-[#000] mt-[2px] '>{contentData?.popup_interested_university}  </span>
                  <span className='text-[14px] font-[550] text-[#032552] '>{universty_name}</span>
                </div> : null
            }

            {
              program !== undefined ?
                <div className='p-[0px_20px] w-full flex items-center gap-[6px]'>
                  <span className='text-[13px] font-[550] text-[#000] mt-[2px] '> {contentData?.popup_interested_program} </span>
                  <span className='text-[14px] font-[550] text-[#032552] '>{program}</span>
                </div> : null
            }

            {
              degree !== undefined ?
                <div className='p-[0px_20px] w-full flex items-center gap-[6px]'>
                  <span className='text-[13px] font-[550] text-[#000] mt-[2px] '> {contentData?.popup_interested_degree}  </span>
                  <span className='text-[14px] font-[550] text-[#032552] '>{degree}</span>
                </div> : null
            }



          </div>

          <div>
            <div className='flex justify-center w-full p-[0px_12px] mt-[12px] mb-[20px]'>
              <button disabled={btnDisable} onClick={this.handleSubmit} className='bg-[#EF7000] w-full p-[12px_0px] text-white  rounded-[8px] text-[15px] font-[400]'>
                {contentData?.get_information}
              </button>
            </div>

            {successMessage && (
              <div className='text-green-500 text-center mb-[20px]'>{successMessage}</div>
            )}
          </div>




        </div>
      </div>

    );
  }
}

StudyAbroadPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  contentData: PropTypes.object,
  // postStatistics: PropTypes.func.isRequired,
  phoneValidFormat: PropTypes.bool.isRequired

};
const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
  phoneValidFormat: state.Data.phoneValidFormat,

});
const mapDispatchToProps = { postStudyAbroad, changeStateValue }

export default connect(mapStateToProps, mapDispatchToProps)(StudyAbroadPopup);



