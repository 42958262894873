import React, { Component } from 'react'
import PlusSvg from '../svg/PlusSvg'
import MinusSvg from '../svg/MinusSvg'


export class Faq extends Component {
  state = {
    visible: false
  }
  render() {
    const { visible } = this.state
    const { question, answer } = this.props
    return (
      <div className='container  '>

        <div className=' flex flex-col gap-[24px] '>
          <div className='bg-[var(--white)] shadow rounded-[8px]'>
            <div onClick={() => this.setState({ visible: !visible })} className='flex items-center  gap-[16px] bg-[var(--white)] rounded-[8px] p-[8px] cursor-pointer'>
              <div>
                {
                  !visible ?
                    <PlusSvg /> : <MinusSvg />
                }
              </div>


              <h3 className='text-[18px] font-[500] text-[var(--black)]'>
                {question}
              </h3>
            </div>
            {
              visible ?
                <p className='mt-[0px] text-[16px] font-[300] mb-[15px] text-[var(--dark-gray)] ml-[18px] mr-[4px]' dangerouslySetInnerHTML={{ __html: answer }}></p> : null
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Faq